import './src/styles/global.css';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { MainAppContextProvider } from './src/contexts/MainAppContext';

export const wrapPageElement = ({ element }) => (
  <CookiesProvider>
    <MainAppContextProvider>{element}</MainAppContextProvider>
  </CookiesProvider>
);
