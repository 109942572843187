import { navigate } from 'gatsby';
import { URL_API, URL_ASSETS } from 'gatsby-env-variables';
import { useCallback, useEffect, useState } from 'react';
import HttpRequestCache from './HttpRequestCache';

/**
 *
 * @param {*} url
 * @param {*} data
 * @param {*} method
 * @returns
 */

export const API_ERRORS = {
  INVALID_SERVICE: 404, // Invalid route
  BAD_REQUEST: 400, // Valid route but invalid data
  INVALID_USER: 401, // Valid Protected route, user not logged in
  BAD_PERMISSIONS: 403, // Valid Protected route, user not allowed
};

const getCookieValue = (name) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const fetchJSON = async (url, params = {}, method = 'GET', cache = true) => {
  const xsrfToken = getCookieValue('XSRF-TOKEN');
  const headers = xsrfToken ? { 'X-XSRF-TOKEN': xsrfToken } : {};
  const requestUrl = `${URL_API}/${url}`;
  const httpRequestCache = HttpRequestCache.getInstance();

  const cacheData = await httpRequestCache.get(url, params);
  if (cacheData && cache) {
    return cacheData;
  }

  let formData = params;
  if (!(params instanceof FormData)) {
    formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }
  }

  const bodyData = method === 'GET' ? {} : { body: formData };
  console.log('fetchJSON', requestUrl, params);
  return fetch(requestUrl, {
    ...bodyData,
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    headers: {
      ...headers,
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers':
        'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    },
    redirect: 'follow',
    referrer: 'client',
  }).then(async (response) => {
    const json = await response.json();

    if (!response.ok || (json && json.status && json.status === 'Fail')) {
      if (response.status === API_ERRORS.INVALID_USER) {
        navigate('/logout');
      }

      const error = new Error(`HTTP error! Status: ${response.status}`);
      error.status = response.status;
      error.msgs = json && json?.errors ? json.errors : ['An error occurred. Please try again.'];
      console.log('fetchJSON url:', requestUrl, error);
      throw error;
    }

    if (cache) await httpRequestCache.set(url, params, json);

    return json;
  });
};

const useGETHttpRequest = (url, params = {}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cacheIndex = HttpRequestCache.getIndex(url, params);

  const fetcher = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setData(null);

    try {
      const response = await fetchJSON(url, params);

      const finalData =
        response?.data && params && params?.limit && typeof params.limit === 'number' && params.limit > 0 && response?.data?.slice
          ? response.data.slice(0, params.limit)
          : response?.data
          ? response?.data
          : response;

      setData(finalData);
      setIsLoading(false);
      setError(null);
    } catch (error) {
      setIsLoading(false);
      setData(null);
      setError(error);
    }
  }, [cacheIndex]);

  useEffect(() => {
    console.log('useGETHttpRequest useEffect, url', url);
    if (url) fetcher();
  }, [url, cacheIndex]);

  return { data, error, isLoading };
};

const useCollectionRequest = (url, limit = false) => {
  const { data, error, isLoading } = useGETHttpRequest(url, { limit });
  return { data, isLoading, error };
};

export const useGetBooks = (limit = false) => {
  const { data, error, isLoading } = useCollectionRequest(`book?publish_type=Book`, limit);
  return { books: data, isLoading, error };
};

export const useGetDasboardInformation = () => {
  const { data, error, isLoading } = useCollectionRequest(`dashboard`);
  return { books: data, isLoading, error };
};

export const useGetAllBooks = (limit = false) => {
  const { data, error, isLoading } = useCollectionRequest(`book`, limit);
  return { books: data, isLoading, error };
};

export const useGetBook = (id) => {
  const { data, error, isLoading } = useGETHttpRequest(id ? `book/${id}` : null);
  return { book: data, isLoading, error };
};

export const useGetStories = (limit = false) => {
  const { data, error, isLoading } = useCollectionRequest(`book?publish_type=Storie`, limit);
  return { stories: data, isLoading, error };
};

export const useGetBookChapters = (id, limit = false) => {
  const { data, error, isLoading } = useCollectionRequest(id ? `book/${id}/chapter` : null, limit);
  return { chapters: data, isLoading, error };
};

export const useGetBookChapter = (id) => {
  const { data, error, isLoading } = useGETHttpRequest(id ? `chapter/${id}` : null);
  return { chapter: data, isLoading, error };
};

export const useGetUser = (id) => {
  const { data, error, isLoading } = useGETHttpRequest(id ? `user/${id}` : null);
  data.avatar = data.avatar ? `${URL_ASSETS}${data.avatar}` : null;
  return { user: data, isLoading, error: error };
};
