// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-razorpay-js": () => import("./../../../src/pages/account-razorpay.js" /* webpackChunkName: "component---src-pages-account-razorpay-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-chapter-js": () => import("./../../../src/pages/chapter.js" /* webpackChunkName: "component---src-pages-chapter-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-free-chapter-js": () => import("./../../../src/pages/free-chapter.js" /* webpackChunkName: "component---src-pages-free-chapter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-login-razorpay-js": () => import("./../../../src/pages/login-razorpay.js" /* webpackChunkName: "component---src-pages-login-razorpay-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-pricing-guide-index-js": () => import("./../../../src/pages/pricing-guide/index.js" /* webpackChunkName: "component---src-pages-pricing-guide-index-js" */),
  "component---src-pages-pricing-guide-razorpay-js": () => import("./../../../src/pages/pricing-guide-razorpay.js" /* webpackChunkName: "component---src-pages-pricing-guide-razorpay-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-and-cancellation-policy-js": () => import("./../../../src/pages/refund-and-cancellation-policy.js" /* webpackChunkName: "component---src-pages-refund-and-cancellation-policy-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-subscription-index-js": () => import("./../../../src/pages/subscription/index.js" /* webpackChunkName: "component---src-pages-subscription-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-for-contacting-us-js": () => import("./../../../src/pages/thank-you-for-contacting-us.js" /* webpackChunkName: "component---src-pages-thank-you-for-contacting-us-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

