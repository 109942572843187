class HttpRequestCache {
  constructor() {
    this.cache = {};
  }

  static getInstance() {
    return _instance;
  }

  async getAll() {
    return this.cache;
  }

  async get(url, params = {}) {
    const index = HttpRequestCache.getIndex(url, params);
    const record = this.cache[index];
    if (!record) return null;

    const now = new Date();
    const deltaTime = (now - record.timestamp) / 1000;
    if (deltaTime > 600) {
      this.clear(url, params);
      return null;
    }

    return record.data;
  }

  static getIndex(url, params = {}) {
    const urlParams = new URLSearchParams(params);
    return `${url}?${urlParams.toString()}`;
  }

  async set(url, params = {}, data) {
    if (data === undefined) return;
    const index = HttpRequestCache.getIndex(url, params);

    this.cache[index] = {
      url: url,
      timestamp: new Date(),
      data: data,
    };
  }

  async clear(url, params = {}) {
    const index = HttpRequestCache.getIndex(url, params);

    this.cache[index] = null;
    delete this.cache[index];
  }

  async clearAll() {
    this.cache = {};
  }
}
const _instance = new HttpRequestCache();

if (typeof window !== 'undefined') window.HttpRequestCache = HttpRequestCache;

export default HttpRequestCache;
